import React, { Component } from "react";
import "./bottomDrawer.scss";

class BottomDrawer extends Component {
  render() {
    const { heading, children, isOpen, onClose } = this.props;

    if (!isOpen) return null;

    return (
      <div className="bottomDrawerOverlay" onClick={() => onClose()}>
        <div className="bottomDrawer" onClick={(e) => e.stopPropagation()}>
          <div className="bottomDrawerHeader">
            <h2 className="bottomDrawerHeading">{heading}</h2>
            <img
              src="https://d1flzashw70bti.cloudfront.net/original/images/opdash/sidemenu/closecross.svg"
              alt="Close"
              className="bottomDrawerCloseIcon"
              onClick={() => onClose()}
            />
          </div>
          <div className="bottomDrawerContent">{children}</div>
        </div>
      </div>
    );
  }
}

export default BottomDrawer;
