import React, { Component } from "react";
import { Fetch } from "../../utils/fetch";
import Navbar from "../../components/newNavBar";
import "./home.scss";
import Loader from "../../utils/loader";
import { message, Modal, DatePicker, Button } from "antd";
import moment from "moment";

// import { useHistory } from "react-router-dom";
import jwt from "jsonwebtoken";
import MobileMainHeader from "../../components/headers/mobileMainHeader";
import { MenuSlideDrawer } from "../../components/slideDrawer/SlideDrawer";
import SideMenu from "../../components/Layout/SideMenu";
import { operatorType } from "../../config";
import EmptyView from "../../components/emptyView";

class Home extends Component {
  state = {
    loading: true,
    showRenewModal: false,
    showExtendModal: false,
    isMobile: false,
    showSuccesModal: false,
    selectedDeal: undefined,
    renewId: undefined,
    selectedRenewDeal: undefined,
    showSideMenu: false,
  };

  async componentDidMount() {
    try {
      const regex =
        /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
      const isMobile = regex.test(navigator.userAgent);
      const token = localStorage.getItem("idToken");
      const decodedToken = jwt.decode(token);
      const userType = decodedToken.userType;
      let details;

      if (userType === operatorType.BUSOWNER) {
        details = await this.fetchMgProfileDetails();
      } else {
        details = await this.fetchProfileDetails();
      }
      this.setState({
        loading: false,
        isMobile,
        details,
        userType: userType,
      });
    } catch (err) {
      console.log(err);
    }
  }

  fetchProfileDetails = async () => {
    const url = `/user/`;
    const options = {
      method: "get",
    };
    const response = await Fetch(url, options);
    if (response) {
      return response;
    } else {
      message.error("no data found");
      return;
    }
  };

  fetchMgProfileDetails = async () => {
    const url = `/user/revenueDetails`;
    const options = {
      method: "get",
    };
    const response = await Fetch(url, options);
    if (response) {
      return response;
    } else {
      message.error("no data found");
      return;
    }
  };

  extendDeal = (deal) => {
    this.setState({ showExtendModal: true, selectedDeal: deal });
  };

  renewDeal = (deal) => {
    this.setState({ showRenewModal: true, selectedRenewDeal: deal });
  };

  handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  showDeals = () => {
    window.location.href = "deals";
  };

  setEndDate = (e, string) => {
    this.setState({ endDate: string });
  };

  setStartDate = (e, string) => {
    this.setState({ startDate: string });
  };

  disabledStartDate = (current) => {
    return current && current < moment().endOf("day");
  };

  extendSelectedDeal = async () => {
    this.setState({ loading: true });
    const { selectedDeal, endDate } = this.state;
    const url = `/user/updateCampaign`;
    const campaignUpdate = {};
    campaignUpdate.selectedCampaignId = selectedDeal._id;
    campaignUpdate.endDate = moment(endDate, "DD-MM-YYYY").valueOf();

    const options = {
      method: "post",
      data: campaignUpdate,
    };
    const response = await Fetch(url, options);
    if (response) {
      const details = await this.fetchProfileDetails();
      this.setState({
        showExtendModal: false,
        showSuccesModal: true,
        loading: false,
        details,
      });
    }
  };

  renewSelectedDeal = async () => {
    this.setState({ loading: true });
    const { selectedRenewDeal, startDate, endDate } = this.state;
    const url = `/user/RenewCampaign`;
    const campaignUpdate = {};
    campaignUpdate.selectedCampaign = selectedRenewDeal;
    campaignUpdate.startDate = moment(startDate, "DD-MM-YYYY").valueOf();
    campaignUpdate.endDate = moment(endDate, "DD-MM-YYYY").valueOf();

    const options = {
      method: "post",
      data: campaignUpdate,
    };
    const response = await Fetch(url, options);
    if (response) {
      const details = await this.fetchProfileDetails();
      this.setState({
        selectedRenewDeal: false,
        showSuccesModal: true,
        renewId: response[0].operatorDeal.name,
        loading: false,
        details,
      });
    }
  };

  toggleSideMenu = () => {
    const { showSideMenu } = this.state;
    this.setState({
      showSideMenu: !showSideMenu,
    });
  };

  render() {
    const {
      loading,
      isMobile,
      details,
      showRenewModal,
      showExtendModal,
      selectedDeal,
      selectedRenewDeal,
      showSuccesModal,
      renewId,
      showSideMenu,
      userType,
    } = this.state;

    let active = 1;

    if (loading) {
      return (
        <div className="loader">
          <Loader />
        </div>
      );
    }
    if (isMobile) {
      {
        return (
          <>
            <MobileMainHeader toggleSideMenu={this.toggleSideMenu} />

            {showSideMenu ? (
              <MenuSlideDrawer
                toggleSideMenu={this.toggleSideMenu}
                showSideMenu={showSideMenu}
                isMobile={isMobile}
              >
                <SideMenu
                  active={active}
                  toggleSideMenu={this.toggleSideMenu}
                  isMobile={isMobile}
                />
              </MenuSlideDrawer>
            ) : null}

            {userType === operatorType.BUSOWNER ? (
              <div className="home" style={{ paddingTop: "45px" }}>
                <div className="homeHeader">
                  <p>Today’s Sales</p>
                  <p className="lastUpdatedHeader">
                    Includes sales from zingbus channels only
                  </p>
                  <p className="lastUpdatedHeader">
                    Last updated on
                    {" " + moment(details.lastUpdatedAt).format("LL")} at{" "}
                    {moment(details.lastUpdatedAt).format("LT")}
                  </p>
                </div>

                <div className="singleGrid">
                  <div>
                    <img
                      src="https://d1flzashw70bti.cloudfront.net/original/images/operator/gridIcon/cash.svg"
                      alt="revenue"
                    ></img>
                    <span>Revenue</span>
                  </div>
                  <p>
                    ₹ {details.revenue > 0 ? details.revenue.toFixed(2) : 0}
                  </p>
                </div>
                <div id="grid">
                  <div>
                    <div>
                      <img
                        src="https://d1flzashw70bti.cloudfront.net/original/images/operator/gridIcon/seatcount.svg"
                        alt="seatCount"
                      ></img>
                      <span>Seat Sold</span>
                    </div>
                    <p>
                      {" "}
                      {userType !== operatorType.BUSOWNER
                        ? details.confirmedSeats || 0
                        : details.seatsSold || 0}
                    </p>
                  </div>
                  <div>
                    <div>
                      <img
                        src="https://d1flzashw70bti.cloudfront.net/original/images/operator/gridIcon/cancelled.svg"
                        alt="cancelled"
                      ></img>
                      <span>Cancellation Charges </span>
                    </div>
                    <p>{details.cancelledSeats || 0}</p>
                  </div>
                </div>
                {/* <div className="singleGrid">
                <div>
                  <img
                    src="https://d1flzashw70bti.cloudfront.net/original/images/operator/gridIcon/donation.svg"
                    alt="amount"
                  ></img>
                  <span>Last Payout Amount </span>
                </div>
                <p>₹ 3456</p>
              </div> */}
                <p className="headings">Notifications</p>
                {details.dealsRenew && details.dealsExpired.length > 0
                  ? details.dealsRenew.map((obj) => {
                      return (
                        <div className="notification-item-warning">
                          <div className="expireColor">
                            <div>
                              <p>{obj.operatorDeal.type} deal expires</p>
                              <p> {moment(obj.endDate).format("ll")}</p>
                              <p>Campaign Code : {obj.name}</p>
                            </div>
                            <button
                              className="notificationBtn"
                              onClick={() => this.extendDeal(obj)}
                            >
                              Extend Deal{" "}
                            </button>
                          </div>
                        </div>
                      );
                    })
                  : null}
                {details.dealsExpired && details.dealsExpired.length > 0
                  ? details.dealsExpired.map((obj) => {
                      return (
                        <div className="notification-item-expired">
                          <div className="expireColor">
                            <div>
                              <p>{obj.operatorDeal.type} expires</p>
                              <p>{moment(obj.endDate).format("ll")}</p>
                              <p>Campaign Code : {obj.name}</p>
                            </div>
                            <button
                              className="notificationBtn"
                              onClick={() => this.renewDeal(obj)}
                            >
                              Renew Deal{" "}
                            </button>
                          </div>
                        </div>
                      );
                    })
                  : null}
                {userType !== operatorType.BUSOWNER ? (
                  <>
                    <p>Quick Actions</p>
                    <div className="quickActions">
                      <div>
                        <img
                          src="https://d1flzashw70bti.cloudfront.net/original/images/operatorDashboard/navbar/deals.svg"
                          alt="pricing"
                        />
                        <span>{"  "}Want to create a new deal?</span>
                      </div>
                      <button className="dealBtn" onClick={this.showDeals}>
                        Create Deal{" "}
                      </button>
                    </div>
                  </>
                ) : null}
                {selectedDeal ? (
                  <>
                    <Modal
                      title="Extend Deal"
                      open={showExtendModal}
                      footer={null}
                      centered
                      onCancel={() =>
                        this.setState({
                          showExtendModal: false,
                          selectedDeal: undefined,
                        })
                      }
                    >
                      <p>
                        Enter the new date upto which you want to extend the
                        deal
                      </p>
                      <div className="ModalDealDetails">
                        <div>
                          <div>
                            <img
                              src={
                                selectedDeal.operatorDeal.type == "LASTMINUTE"
                                  ? "https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/lastMin.svg"
                                  : selectedDeal.operatorDeal.type ==
                                    "EARLYBIRD"
                                  ? "https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/earlyBird.svg"
                                  : "https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/custom.svg"
                              }
                              alt="custom"
                            />
                            <span>{selectedDeal.operatorDeal.type}</span>
                          </div>
                          <div>
                            <span>{selectedDeal.operatorDeal.name}</span>
                          </div>
                        </div>
                        <p>
                          Services :{" "}
                          {selectedDeal.serviceName
                            ? selectedDeal.serviceName[0].fromCity.name
                            : null}{" "}
                          to{" "}
                          {selectedDeal.serviceName
                            ? selectedDeal.serviceName[0].toCity.name
                            : null}{" "}
                          <span>
                            {selectedDeal.serviceName &&
                            selectedDeal.serviceName.length > 1
                              ? `+ ${selectedDeal.serviceName.length - 1}`
                              : null}
                          </span>
                        </p>
                        <p>
                          Discount Type :{" "}
                          {selectedDeal
                            ? selectedDeal.operatorDeal.discountType
                            : null}
                        </p>
                        {selectedDeal.operatorDeal.discountType ===
                        "PERCENTAGE" ? (
                          <>
                            <p>
                              Discount Percentage :{" "}
                              {selectedDeal
                                ? selectedDeal.operatorDeal.discountValue
                                : null}
                              %
                            </p>
                            <p>
                              Min. Seat Amount : ₹{" "}
                              {selectedDeal
                                ? selectedDeal.operatorDeal.minSeatValue
                                : null}
                            </p>
                            <p>
                              Max. Discount : ₹{" "}
                              {selectedDeal
                                ? selectedDeal.operatorDeal.maxDiscount
                                : null}
                            </p>
                          </>
                        ) : (
                          <>
                            <p>
                              Discount Amount : ₹{" "}
                              {selectedDeal
                                ? selectedDeal.operatorDeal.discountValue
                                : null}
                            </p>
                            <p>
                              Min. Seat Amount : ₹{" "}
                              {selectedDeal
                                ? selectedDeal.operatorDeal.minSeatValue
                                : null}
                            </p>
                          </>
                        )}
                      </div>
                      <div className="ModalDealStart">
                        <p>Deal Start Date</p>
                        <p>
                          {moment(
                            selectedDeal ? selectedDeal.startDate : null
                          ).format("L")}
                        </p>
                      </div>
                      <div className="ModalDealEnd">
                        <p>Deal End Date</p>
                        <DatePicker
                          style={{ width: `100%` }}
                          disabledDate={this.disabledStartDate}
                          inputReadOnly={true}
                          format="DD-MM-YYYY"
                          onChange={this.setEndDate}
                        />
                      </div>
                      <div className="ModalFooter">
                        <button
                          className="cancel"
                          onClick={() =>
                            this.setState({
                              showExtendModal: false,
                              selectedDeal: undefined,
                            })
                          }
                        >
                          Cancel
                        </button>

                        <button
                          className="primary"
                          onClick={() => this.extendSelectedDeal()}
                        >
                          Extend
                        </button>
                      </div>
                    </Modal>
                  </>
                ) : null}
                {selectedRenewDeal ? (
                  <Modal
                    title="Renew Deal"
                    open={showRenewModal}
                    footer={null}
                    centered
                    onCancel={() =>
                      this.setState({
                        showRenewModal: false,
                        selectedRenewDeal: undefined,
                      })
                    }
                  >
                    <p>Enter the new end date & start date to renew deal</p>
                    <div className="ModalDealDetails">
                      <div>
                        <div>
                          <img
                            src={
                              selectedRenewDeal.operatorDeal.type ==
                              "LASTMINUTE"
                                ? "https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/lastMin.svg"
                                : selectedRenewDeal.operatorDeal.type ==
                                  "EARLYBIRD"
                                ? "https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/earlyBird.svg"
                                : "https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/custom.svg"
                            }
                            alt="custom"
                          />
                          <span>{selectedRenewDeal.operatorDeal.type}</span>
                        </div>
                        <div>
                          <span>{selectedRenewDeal.operatorDeal.name}</span>
                        </div>
                      </div>
                      <p>
                        Services :{" "}
                        {selectedRenewDeal.serviceName
                          ? selectedRenewDeal.serviceName[0].fromCity.name
                          : null}{" "}
                        to{" "}
                        {selectedRenewDeal.serviceName
                          ? selectedRenewDeal.serviceName[0].toCity.name
                          : null}{" "}
                        <span>
                          {selectedRenewDeal.serviceName &&
                          selectedRenewDeal.serviceName.length > 1
                            ? `+ ${selectedRenewDeal.serviceName.length - 1}`
                            : null}
                        </span>
                      </p>
                      <p>
                        Discount Type :{" "}
                        {selectedRenewDeal
                          ? selectedRenewDeal.operatorDeal.discountType
                          : null}
                      </p>
                      {selectedRenewDeal.operatorDeal.discountType ===
                      "PERCENTAGE" ? (
                        <>
                          <p>
                            Discount Percentage :{" "}
                            {selectedRenewDeal
                              ? selectedRenewDeal.operatorDeal.discountValue
                              : null}
                            %
                          </p>
                          <p>
                            Min. Seat Amount : ₹{" "}
                            {selectedRenewDeal
                              ? selectedRenewDeal.operatorDeal.minSeatValue
                              : null}
                          </p>
                          <p>
                            Max. Discount : ₹{" "}
                            {selectedRenewDeal
                              ? selectedRenewDeal.operatorDeal.maxDiscount
                              : null}
                          </p>
                        </>
                      ) : (
                        <>
                          <p>
                            Discount Amount : ₹{" "}
                            {selectedRenewDeal
                              ? selectedRenewDeal.operatorDeal.discountValue
                              : null}
                          </p>
                          <p>
                            Min. Seat Amount : ₹{" "}
                            {selectedRenewDeal
                              ? selectedRenewDeal.operatorDeal.minSeatValue
                              : null}
                          </p>
                        </>
                      )}
                    </div>
                    <div className="ModalDealStart">
                      <p>Deal Start Date</p>
                      <DatePicker
                        style={{ width: `100%` }}
                        disabledDate={this.disabledStartDate}
                        inputReadOnly={true}
                        format="DD-MM-YYYY"
                        onChange={this.setStartDate}
                      />
                    </div>
                    <div className="ModalDealEnd">
                      <p>Deal End Date</p>
                      <DatePicker
                        style={{ width: `100%` }}
                        disabledDate={this.disabledStartDate}
                        inputReadOnly={true}
                        format="DD-MM-YYYY"
                        onChange={this.setEndDate}
                      />
                    </div>
                    <div className="ModalFooter">
                      <button
                        className="cancel"
                        onClick={() =>
                          this.setState({
                            showRenewModal: false,
                            selectedRenewDeal: undefined,
                          })
                        }
                      >
                        Cancel
                      </button>

                      <button
                        className="primary"
                        onClick={() => this.renewSelectedDeal()}
                      >
                        Renew
                      </button>
                    </div>
                  </Modal>
                ) : null}
                <Modal
                  centered
                  open={showSuccesModal}
                  closable={false}
                  cancelButtonProps={{ style: { display: "none" } }}
                  onOk={() => this.setState({ showSuccesModal: false })}
                  okText={"Done"}
                  okButtonProps={{
                    style: {
                      background: "#7b2bff",
                      width: "100%",
                      height: "40px",
                      marginTop: "10px",
                      border: "none",
                    },
                  }}
                >
                  <div className="modalDiv">
                    <img
                      src="https://d1flzashw70bti.cloudfront.net/next-gen/images/operator/deals/success.webp"
                      alt="success"
                    />
                    {renewId ? (
                      <>
                        <p className="modalHeader">
                          Your deal has been renewed
                        </p>
                        <p className="modalSubHeader">
                          New Campaign Code: {renewId}
                        </p>
                      </>
                    ) : (
                      <p className="modalHeader">Your Deal has been extended</p>
                    )}
                  </div>
                </Modal>
              </div>
            ) : (
              <div>
                <EmptyView message="Comming Soon" />
              </div>
            )}
          </>
        );
      }
    } else {
      return (
        <div>
          <Navbar selectedKey={1} isMobile={isMobile} />
          <div class="container">
            <div className="header">
              <p className="headings">Today’s Sales</p>
            </div>
            <p className="lastUpdatedHeader">
              Includes sales from zingbus channels only | Last updated on
              {" " + moment(details.lastUpdatedAt).format("LL")} at{" "}
              {moment(details.lastUpdatedAt).format("LT")}
            </p>
            <div id="grid">
              <div>
                <div>
                  <img
                    src="https://d1flzashw70bti.cloudfront.net/original/images/operator/gridIcon/cash.svg"
                    alt="revenue"
                  ></img>
                  <span>Revenue(Seats Sold)</span>
                </div>
                <p>₹ {details.revenue > 0 ? details.revenue.toFixed(2) : 0}</p>
              </div>
              <div>
                <div>
                  <img
                    src="https://d1flzashw70bti.cloudfront.net/original/images/operator/gridIcon/seatcount.svg"
                    alt="seatCount"
                  ></img>
                  <span>Seat Count</span>
                </div>
                <p>
                  {userType !== operatorType.BUSOWNER
                    ? details.confirmedSeats || 0
                    : details.seatsSold || 0}
                </p>
              </div>
              <div>
                <div>
                  <img
                    src="https://d1flzashw70bti.cloudfront.net/original/images/operator/gridIcon/cancelled.svg"
                    alt="cancellation"
                  ></img>
                  <span>Cancellation Charges </span>
                </div>
                <p>{details.cancelledSeats}</p>
              </div>
              {/* <div>
                <div>
                  <img
                    src="https://d1flzashw70bti.cloudfront.net/original/images/operator/gridIcon/donation.svg"
                    alt="donation"
                  ></img>
                  <span>Last Payout Amount</span>
                </div>
                <span>₹ 3456 </span> <span>on 23rd Sept, 2023</span>
              </div> */}
            </div>
            {(details.dealsRenew && details.dealsRenew.length > 0) ||
            (details.dealsExpired && details.dealsExpired.length > 0) ? (
              <>
                <p className="headings">Notifications</p>
                <div class="notification-container">
                  {details.dealsRenew
                    ? details.dealsRenew.map((obj) => {
                        return (
                          <div className="notification-item-warning">
                            <div className="expireColor">
                              <div>
                                <p>
                                  {obj.operatorDeal.type} deal expires on{" "}
                                  {moment(obj.endDate).format("ll")}
                                </p>
                                <p>Campaign Code : {obj.name}</p>
                              </div>
                              <button
                                className="dealBtn"
                                onClick={() => this.extendDeal(obj)}
                              >
                                Extend Deal{" "}
                              </button>
                            </div>
                          </div>
                        );
                      })
                    : null}
                  {details.dealsExpired
                    ? details.dealsExpired.map((obj) => {
                        return (
                          <div className="notification-item-expired">
                            <div className="expireColor">
                              <div>
                                <p>
                                  {obj.operatorDeal.type} deal expires on{" "}
                                  {moment(obj.endDate).format("ll")}{" "}
                                </p>
                                <p>Campaign Code : {obj.name}</p>
                              </div>
                              <button
                                className="dealBtn"
                                onClick={() => this.renewDeal(obj)}
                              >
                                Renew Deal{" "}
                              </button>
                            </div>
                          </div>
                        );
                      })
                    : null}
                </div>
              </>
            ) : null}
            {userType !== operatorType.BUSOWNER ? (
              <>
                {" "}
                <p className="headings">Quick Actions</p>
                <div className="quickActions">
                  <img
                    src="https://d1flzashw70bti.cloudfront.net/original/images/operatorDashboard/navbar/deals.svg"
                    alt="pricing"
                  />
                  <span>{"  "}Want to create a new deal?</span>
                  <button className="dealBtn" onClick={this.showDeals}>
                    Create Deal{" "}
                  </button>
                </div>
              </>
            ) : null}
          </div>
          {selectedDeal ? (
            <>
              <Modal
                title="Extend Deal"
                open={showExtendModal}
                footer={null}
                centered
                onCancel={() =>
                  this.setState({
                    showExtendModal: false,
                    selectedDeal: undefined,
                  })
                }
              >
                <p>Enter the new date upto which you want to extend the deal</p>
                <div className="ModalDealDetails">
                  <div>
                    <div>
                      <img
                        src={
                          selectedDeal.operatorDeal.type == "LASTMINUTE"
                            ? "https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/lastMin.svg"
                            : selectedDeal.operatorDeal.type == "EARLYBIRD"
                            ? "https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/earlyBird.svg"
                            : "https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/custom.svg"
                        }
                        alt="custom"
                      />
                      <span>{selectedDeal.operatorDeal.type}</span>
                    </div>
                    <div>
                      <span>{selectedDeal.operatorDeal.name}</span>
                    </div>
                  </div>
                  <p>
                    Services :{" "}
                    {selectedDeal.serviceName
                      ? selectedDeal.serviceName[0].fromCity.name
                      : null}{" "}
                    to{" "}
                    {selectedDeal.serviceName
                      ? selectedDeal.serviceName[0].toCity.name
                      : null}{" "}
                    <span>
                      {selectedDeal.serviceName &&
                      selectedDeal.serviceName.length > 1
                        ? `+ ${selectedDeal.serviceName.length - 1}`
                        : null}
                    </span>
                  </p>
                  <p>
                    Discount Type :{" "}
                    {selectedDeal
                      ? selectedDeal.operatorDeal.discountType
                      : null}
                  </p>
                  {selectedDeal.operatorDeal.discountType === "PERCENTAGE" ? (
                    <>
                      <p>
                        Discount Percentage :{" "}
                        {selectedDeal
                          ? selectedDeal.operatorDeal.discountValue
                          : null}
                        %
                      </p>
                      <p>
                        Min. Seat Amount : ₹{" "}
                        {selectedDeal
                          ? selectedDeal.operatorDeal.minSeatValue
                          : null}
                      </p>
                      <p>
                        Max. Discount : ₹{" "}
                        {selectedDeal
                          ? selectedDeal.operatorDeal.maxDiscount
                          : null}
                      </p>
                    </>
                  ) : (
                    <>
                      <p>
                        Discount Amount : ₹{" "}
                        {selectedDeal
                          ? selectedDeal.operatorDeal.discountValue
                          : null}
                      </p>
                      <p>
                        Min. Seat Amount : ₹{" "}
                        {selectedDeal
                          ? selectedDeal.operatorDeal.minSeatValue
                          : null}
                      </p>
                    </>
                  )}
                </div>
                <div className="ModalDealStart">
                  <p>Deal Start Date</p>
                  <p>
                    {moment(
                      selectedDeal ? selectedDeal.startDate : null
                    ).format("L")}
                  </p>
                </div>
                <div className="ModalDealEnd">
                  <p>Deal End Date</p>
                  <DatePicker
                    style={{ width: `100%` }}
                    disabledDate={this.disabledStartDate}
                    inputReadOnly={true}
                    format="DD-MM-YYYY"
                    onChange={this.setEndDate}
                  />
                </div>
                <div className="ModalFooter">
                  <button
                    className="cancel"
                    onClick={() =>
                      this.setState({
                        showExtendModal: false,
                        selectedDeal: undefined,
                      })
                    }
                  >
                    Cancel
                  </button>

                  <button
                    className="primary"
                    onClick={() => this.extendSelectedDeal()}
                  >
                    Extend
                  </button>
                </div>
              </Modal>
            </>
          ) : null}
          {selectedRenewDeal ? (
            <Modal
              title="Renew Deal"
              open={showRenewModal}
              footer={null}
              centered
              onCancel={() =>
                this.setState({
                  showRenewModal: false,
                  selectedRenewDeal: undefined,
                })
              }
            >
              <p>Enter the new end date & start date to renew deal</p>
              <div className="ModalDealDetails">
                <div>
                  <div>
                    <img
                      src={
                        selectedRenewDeal.operatorDeal.type == "LASTMINUTE"
                          ? "https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/lastMin.svg"
                          : selectedRenewDeal.operatorDeal.type == "EARLYBIRD"
                          ? "https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/earlyBird.svg"
                          : "https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/custom.svg"
                      }
                      alt="custom"
                    />
                    <span>{selectedRenewDeal.operatorDeal.type}</span>
                  </div>
                  <div>
                    <span>{selectedRenewDeal.operatorDeal.name}</span>
                  </div>
                </div>
                <p>
                  Services :{" "}
                  {selectedRenewDeal.serviceName
                    ? selectedRenewDeal.serviceName[0].fromCity.name
                    : null}{" "}
                  to{" "}
                  {selectedRenewDeal.serviceName
                    ? selectedRenewDeal.serviceName[0].toCity.name
                    : null}{" "}
                  <span>
                    {selectedRenewDeal.serviceName &&
                    selectedRenewDeal.serviceName.length > 1
                      ? `+ ${selectedRenewDeal.serviceName.length - 1}`
                      : null}
                  </span>
                </p>
                <p>
                  Discount Type :{" "}
                  {selectedRenewDeal
                    ? selectedRenewDeal.operatorDeal.discountType
                    : null}
                </p>
                {selectedRenewDeal.operatorDeal.discountType ===
                "PERCENTAGE" ? (
                  <>
                    <p>
                      Discount Percentage :{" "}
                      {selectedRenewDeal
                        ? selectedRenewDeal.operatorDeal.discountValue
                        : null}
                      %
                    </p>
                    <p>
                      Min. Seat Amount : ₹{" "}
                      {selectedRenewDeal
                        ? selectedRenewDeal.operatorDeal.minSeatValue
                        : null}
                    </p>
                    <p>
                      Max. Discount : ₹{" "}
                      {selectedRenewDeal
                        ? selectedRenewDeal.operatorDeal.maxDiscount
                        : null}
                    </p>
                  </>
                ) : (
                  <>
                    <p>
                      Discount Amount : ₹{" "}
                      {selectedRenewDeal
                        ? selectedRenewDeal.operatorDeal.discountValue
                        : null}
                    </p>
                    <p>
                      Min. Seat Amount : ₹{" "}
                      {selectedRenewDeal
                        ? selectedRenewDeal.operatorDeal.minSeatValue
                        : null}
                    </p>
                  </>
                )}
              </div>
              <div className="ModalDealStart">
                <p>Deal Start Date</p>
                <DatePicker
                  style={{ width: `100%` }}
                  disabledDate={this.disabledStartDate}
                  inputReadOnly={true}
                  format="DD-MM-YYYY"
                  onChange={this.setStartDate}
                />
              </div>
              <div className="ModalDealEnd">
                <p>Deal End Date</p>
                <DatePicker
                  style={{ width: `100%` }}
                  disabledDate={this.disabledStartDate}
                  inputReadOnly={true}
                  format="DD-MM-YYYY"
                  onChange={this.setEndDate}
                />
              </div>
              <div className="ModalFooter">
                <button
                  className="cancel"
                  onClick={() =>
                    this.setState({
                      showRenewModal: false,
                      selectedRenewDeal: undefined,
                    })
                  }
                >
                  Cancel
                </button>

                <button
                  className="primary"
                  onClick={() => this.renewSelectedDeal()}
                >
                  Renew
                </button>
              </div>
            </Modal>
          ) : null}
          <Modal
            centered
            open={showSuccesModal}
            closable={false}
            cancelButtonProps={{ style: { display: "none" } }}
            onOk={() => this.setState({ showSuccesModal: false })}
            okText={"Done"}
            okButtonProps={{
              style: {
                background: "#7b2bff",
                width: "100%",
                height: "40px",
                marginTop: "10px",
                border: "none",
              },
            }}
          >
            <div className="modalDiv">
              <img
                src="https://d1flzashw70bti.cloudfront.net/next-gen/images/operator/deals/success.webp"
                alt="success"
              />
              {renewId ? (
                <>
                  <p className="modalHeader">Your deal has been renewed</p>
                  <p className="modalSubHeader">New Campaign Code: {renewId}</p>
                </>
              ) : (
                <p className="modalHeader">Your Deal has been extended</p>
              )}
            </div>
          </Modal>
        </div>
      );
    }
  }
}

export default Home;
