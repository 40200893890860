import React, { Component } from "react";
import "./mobileMainHeader.scss";
import MobileSubHeader from "./mobileSubHeader.js";
import { Link, withRouter } from "react-router-dom/cjs/react-router-dom.min";

class MobileMainHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMainHeaderHidden: false,
      lastScrollY: 0,
    };
  }

  handleScroll = () => {
    const { lastScrollY } = this.state;

    const currentScrollY = window.scrollY;

    if (this.props.hasSubHeader) {
      if (currentScrollY > lastScrollY && currentScrollY > 0) {
        this.setState({ isMainHeaderHidden: true });
      } else {
        this.setState({ isMainHeaderHidden: false });
      }
    }

    this.setState({ lastScrollY: currentScrollY });
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    const { isMainHeaderHidden } = this.state;
    const { hasSubHeader, toggleSideMenu, location, toggleBottomDrawer } =
      this.props;

    // extract route name from url
    const routeName = location.pathname.replace("/", "");

    return (
      <>
        <div
          className={
            isMainHeaderHidden ? "mobileMainHeader hidden" : "mobileMainHeader"
          }
        >
          <div className="logo">
            <Link to="/">
              <img
                src="https://d1flzashw70bti.cloudfront.net/original/images/operator/desktop/logo.svg"
                alt="Zingbus Logo"
              />
            </Link>
          </div>
          <div className="menuIcon" onClick={toggleSideMenu}>
            <img
              src="https://d1flzashw70bti.cloudfront.net/original/images/menuHamburgerIcon.svg"
              alt="Menu Icon"
            />
          </div>
        </div>
        {hasSubHeader && (
          <MobileSubHeader
            routeName={routeName}
            isMainHeaderHidden={isMainHeaderHidden}
            toggleBottomDrawer={toggleBottomDrawer}
          />
        )}
        <div className="pageContent">{this.props.children}</div>
      </>
    );
  }
}

export default withRouter(MobileMainHeader);
