import React, { Component } from "react";
import "./assignDriverForm.scss";
import { validateMobileNumber } from "../../utils/common";
import { Fetch } from "../../utils/fetch";

class AssignDriverForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tripData: props.tripData,
      mobileNumber: "",
      driverName: "",
      rcNumber: "",
      errors: {},
    };
  }

  handleChange = (field, value) => {
    const errors = { ...this.state.errors };
    errors[field] = "";
    this.setState({ [field]: value, errors });
  };

  handleSubmit = async () => {
    const { mobileNumber, driverName, rcNumber, tripData } = this.state;
    const errors = {};

    if (
      (!mobileNumber && !this.props.driverMobileNo) || // case 1: Neither state nor props has a value
      (mobileNumber && !validateMobileNumber(mobileNumber)) || // case 2: User enters a fresh number in state, but it's invalid
      (!mobileNumber &&
        this.props.driverMobileNo &&
        !validateMobileNumber(this.props.driverMobileNo)) // case 3: Value is only in props, user tries to change it, but it's invalid
    ) {
      errors.mobileNumber = "Invalid mobile number";
    }
    if (!driverName && !this.props.driverName) {
      errors.driverName = "Driver name is required";
    }
    if (!rcNumber && !this.props.rcNumber) {
      errors.rcNumber = "RC number is required";
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }

    const path = `/cabOperator/assignCabDriver`;
    const body = {
      _id: tripData.id,
      driverMobileNo:
        !mobileNumber && this.props.driverMobileNo
          ? +this.props.driverMobileNo
          : +mobileNumber,
      driverName:
        !driverName && this.props.driverName
          ? this.props.driverName
          : driverName,
      rcNumber:
        !rcNumber && this.props.rcNumber ? this.props.rcNumber : rcNumber,
    };
    const options = {
      method: "post",
      data: body,
    };
    const assignedDriverDetails = await Fetch(path, options);

    if (
      assignedDriverDetails &&
      assignedDriverDetails.length &&
      assignedDriverDetails[0].driverDetails &&
      assignedDriverDetails[0].vehicleDetails
    ) {
      // send assignment communication to cab driver
      const isDriverUpdate =
        this.props.driverMobileNo ||
        this.props.driverName ||
        this.props.rcNumber;
      const path = "/cabOperator/driver/communication";
      const body = {
        _id: tripData.id,
        event: isDriverUpdate
          ? "driverAssignedCabBookingGroupUpdate"
          : "driverAssignedCabBookingGroup",
      };
      const options = {
        method: "post",
        data: body,
      };
      await Fetch(path, options);

      await this.props.onCloseDrawer(assignedDriverDetails[0]);
      await this.props.togglePopUp(true);
    } else {
      await this.props.onCloseDrawer(null);
      await this.props.togglePopUp(false);
    }
  };

  isFieldFocused = (fieldId) => {
    return document.activeElement.id === fieldId;
  };

  render() {
    const { mobileNumber, driverName, rcNumber, errors } = this.state;

    return (
      <div className="assignDriverForm">
        <div className="formField">
          <label htmlFor="mobileNumber">Mobile Number</label>
          <input
            type="text"
            id="mobileNumber"
            placeholder="Type here"
            value={
              !mobileNumber &&
              this.props.driverMobileNo &&
              !this.isFieldFocused("mobileNumber")
                ? this.props.driverMobileNo
                : mobileNumber
            }
            onChange={(e) =>
              this.handleChange(
                "mobileNumber",
                e.target.value.replace(/\D/g, "")
              )
            }
            autoComplete="tel"
            maxLength={10}
          />
          {errors.mobileNumber && (
            <span className="errorText">{errors.mobileNumber}</span>
          )}
        </div>

        <div className="formField">
          <label htmlFor="driverName">Driver Name</label>
          <input
            type="text"
            id="driverName"
            placeholder="Type here"
            value={
              !driverName &&
              this.props.driverName &&
              !this.isFieldFocused("driverName")
                ? this.props.driverName
                : driverName
            }
            onChange={(e) =>
              this.handleChange(
                "driverName",
                e.target.value.replace(/[^a-zA-Z ]/g, "")
              )
            }
            autoComplete="name"
            maxLength={30}
          />
          {errors.driverName && (
            <span className="errorText">{errors.driverName}</span>
          )}
        </div>

        <div className="formField">
          <label htmlFor="rcNumber">RC</label>
          <input
            type="text"
            id="rcNumber"
            placeholder="Type here"
            value={
              !rcNumber &&
              this.props.rcNumber &&
              !this.isFieldFocused("rcNumber")
                ? this.props.rcNumber
                : rcNumber
            }
            onChange={(e) =>
              this.handleChange(
                "rcNumber",
                e.target.value.replace(/[^A-Za-z0-9]/g, "").toUpperCase()
              )
            }
            autoComplete="off"
            maxLength={10}
            pattern="[A-Za-z0-9]*"
          />
          {errors.rcNumber && (
            <span className="errorText">{errors.rcNumber}</span>
          )}
        </div>

        <button
          className={
            !mobileNumber && !driverName && !rcNumber
              ? "assignButton disabled"
              : "assignButton"
          }
          onClick={this.handleSubmit}
          disabled={!mobileNumber && !driverName && !rcNumber}
        >
          Assign
        </button>
      </div>
    );
  }
}

export default AssignDriverForm;
