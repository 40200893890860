import React from "react";
import jwt from "jsonwebtoken";
import "./passengerDetailsTile.scss";
import { formatTime } from "../../utils/common";
import { Fetch } from "../../utils/fetch";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class PassengerDetailsTile extends React.Component {
  state = {
    isViewMoreClicked: false,
    isDialDisabled: false,
  };

  componentDidMount() {
    toast.configure();
  }

  viewMoreDetails = () => {
    const { isViewMoreClicked } = this.state;
    this.setState({ isViewMoreClicked: !isViewMoreClicked });
  };

  handleDial = async (mobileNo) => {
    if (this.state.isDialDisabled) return;

    this.setState({ isDialDisabled: true });
    setTimeout(() => {
      this.setState({ isDialDisabled: false });
    }, 5000);

    const token = localStorage.getItem("idToken");
    const decodedToken = jwt.decode(token);
    const userMobileNo = decodedToken.mobileNo;

    const url = "/cabOperator/clickToCall";
    const options = {
      method: "post",
      data: {
        caller: userMobileNo,
        receiver: mobileNo,
      },
    };

    const response = await Fetch(url, options);
    if (response === "call success") {
      toast.success(
        "Call initiated successfully! You will receive a connecting call soon"
      );
    }
  };

  render() {
    const { bookingData, startTime } = this.props;
    const {
      id,
      name,
      fromStation,
      toStation,
      fromStationDate,
      toStationDate,
      passengers,
      noOfSeats,
      mobileNo,
    } = bookingData;
    const { isViewMoreClicked } = this.state;

    return (
      <div className="passengerDetailsTile">
        <div className="detailsHeader">
          <span className="tripId">{name}</span>
        </div>
        <div className="center">
          <span className="marshalDetails">
            <span className="name">
              {noOfSeats && noOfSeats === 1
                ? `${noOfSeats} seat`
                : `${noOfSeats} seats`}
            </span>
            {startTime + 14400000 > Date.now() ? ( // 14400000: 4hrs
              <div
                className="callWrapper"
                onClick={() => this.handleDial(mobileNo)}
              >
                <img
                  src="https://d1flzashw70bti.cloudfront.net/original/images/optdash/callIcon.svg"
                  alt="call marshal"
                  className="callIcon"
                />
                <span className="text">Call</span>
              </div>
            ) : null}
          </span>
        </div>
        <div className="moreDetails" onClick={() => this.viewMoreDetails()}>
          <span className="viewMoreLessToggle">
            {isViewMoreClicked ? "Less Details" : "View Details"}
          </span>
          <img
            src="https://d1flzashw70bti.cloudfront.net/original/images/optdash/tile/purpleangleright.svg"
            alt="Details Icon"
            className={
              isViewMoreClicked ? "detailsIcon viewLess" : "detailsIcon"
            }
          />
        </div>
        {isViewMoreClicked && (
          <div className="extendedTile">
            <div className="infoGroup">
              <div className="labelAndTime">
                <span className="label">Pickup</span>
                <span className="time">
                  {formatTime(fromStationDate, "h:mm A")}
                </span>
              </div>
              <div className="address">{fromStation.address}</div>
            </div>

            <div className="arrowWrapper">
              <img
                src="https://d1flzashw70bti.cloudfront.net/original/images/opd/tiles/blackarrowright.svg"
                alt="Arrow Icon"
                className="arrowIcon"
              />
            </div>

            <div className="infoGroup">
              <div className="labelAndTime">
                <span className="label">Drop</span>
                <span className="time">
                  {formatTime(toStationDate, "h:mm A")}
                </span>
              </div>
              <div className="address">{toStation.address}</div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default PassengerDetailsTile;
