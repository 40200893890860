import React, { Component } from "react";
import "./emptyView.scss";

class EmptyView extends Component {
  render() {
    const { message } = this.props;
    return (
      <div className="emptyViewContainer">
        <img
          src="https://d1flzashw70bti.cloudfront.net/original/images/opt/dash/emptyzingbuslogo.svg"
          alt="zingbus logo"
          className="emptyViewLogo"
        />
        <span className="emptyViewText">
          {message ? message : "It’s empty in here"}
        </span>
      </div>
    );
  }
}

export default EmptyView;
