import React, { Component } from "react";
import "./appliedFiltersTabs.scss";
import { format } from "date-fns";

class AppliedFiltersTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: [],
    };
  }

  componentDidMount() {
    this.applyFilter();
  }

  applyFilter = () => {
    const {
      fromCityFilter,
      toCityFilter,
      fromDateFilter,
      toDateFilter,
      tripIdFilter,
    } = this.props;
    let appliedFilters = [];
    if (fromDateFilter && toDateFilter) {
      appliedFilters.push({
        fitlerType: "FROM_AND_TO_DATE_FILTER",
        filterText: `${format(fromDateFilter, "d MMM yyyy")} - ${format(
          toDateFilter,
          "d MMM yyyy"
        )}`,
      });
    }
    if (fromCityFilter && toCityFilter) {
      appliedFilters.push({
        fitlerType: "FROM_AND_TO_CITY_FILTER",
        filterText: `${this.props.fromCityFilter} - ${this.props.toCityFilter}`,
      });
    }
    if (tripIdFilter) {
      appliedFilters.push({
        fitlerType: "TRIP_ID_FILTER",
        filterText: `${tripIdFilter}`,
      });
    }
    this.setState({
      filters: appliedFilters,
    });
  };

  removeFilter = (filter) => {
    this.setState((prevState) => ({
      filters: prevState.filters.filter((item) => item !== filter),
    }));
    if (this.props.onRemoveFilters) {
      this.props.onRemoveFilters(filter); // Notify parent component if needed
    }
  };

  render() {
    const {
      fromCityFilter,
      toCityFilter,
      fromDateFilter,
      toDateFilter,
      tripIdFilter,
    } = this.props;
    let appliedFilters = [];
    if (fromDateFilter && toDateFilter) {
      appliedFilters.push({
        fitlerType: "FROM_AND_TO_DATE_FILTER",
        filterText: `${format(fromDateFilter, "d MMM yyyy")} - ${format(
          toDateFilter,
          "d MMM yyyy"
        )}`,
      });
    }
    if (fromCityFilter && toCityFilter) {
      appliedFilters.push({
        fitlerType: "FROM_AND_TO_CITY_FILTER",
        filterText: `${this.props.fromCityFilter} - ${this.props.toCityFilter}`,
      });
    }
    if (tripIdFilter) {
      appliedFilters.push({
        fitlerType: "TRIP_ID_FILTER",
        filterText: `${tripIdFilter}`,
      });
    }

    return (
      <div className="appliedFiltersTabs" style={{ marginTop: "40px" }}>
        <div className="tabsContainer">
          {appliedFilters.map((filter, index) => (
            <div className="tab" key={index}>
              <span className="filterText">{filter.filterText}</span>
              <img
                src="https://d1flzashw70bti.cloudfront.net/original/images/lounges/close-cross.svg"
                alt="Remove filter"
                className="removeIcon"
                onClick={() => this.removeFilter(filter)}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default AppliedFiltersTabs;
