import React from "react";
import "./tripTile.scss";
import moment from "moment";
import { Link } from "react-router-dom";
import { formatDate, formatTime } from "../../utils/common";

class TripTile extends React.Component {
  render() {
    const { tripData, index, isAnyFilterApplied } = this.props;
    const { groupCode, startTime, from, to, seater, passengers } = tripData;

    return (
      <div
        className="tripTile"
        style={
          index === 0 && !isAnyFilterApplied ? { marginTop: "45px" } : null
        }
      >
        <div className="tripHeader">
          <span className="tripId">ID: {groupCode}</span>
          <div className="tripDateAndTime">
            <span className="tripDate">{formatDate(startTime, "DD MMM")}</span>
            <span className="tripTime">{formatTime(startTime, "h:mm A")}</span>
          </div>
        </div>
        <div className="tripRoute">
          <span className="routeFromTo">
            <strong>{from}</strong>
            <img
              src="https://d1flzashw70bti.cloudfront.net/original/images/opd/tiles/blackarrowright.svg"
              alt="Arrow Icon"
              className="arrowIcon"
            />
            <strong>{to}</strong>
          </span>
        </div>
        <div className="tripDetails">
          <div>
            <span className="seater">{seater}</span>
            <img
              src="https://d1flzashw70bti.cloudfront.net/original/images/optdash/tile/dot.svg"
              alt="dot"
            />
            <span className="passengers">{passengers} passengers</span>
          </div>
          <Link
            to={`/trips/tripDetails?_id=${tripData.id}`}
            className="viewDetails"
          >
            View Details
            <img
              src="https://d1flzashw70bti.cloudfront.net/original/images/optdash/tile/purpleangleright.svg"
              alt="Details Icon"
              className="detailsIcon"
            />
          </Link>
        </div>
      </div>
    );
  }
}

export default TripTile;
