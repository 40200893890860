import React from "react";
import "./marshalDetailsTile.scss";

class MarshalDetailsTile extends React.Component {
  handleDial = () => {
    const { connectingMarshal } = this.props;
    window.location.href = `tel:${connectingMarshal.mobileNo}`;
  };

  render() {
    const { tripData, index, connectingMarshal } = this.props;
    const { id, startTime, from, to, seater, passengers } = tripData;

    return (
      <div
        className="marshalDetailsTile"
        style={index === 0 ? { marginTop: "45px" } : null}
      >
        <div className="detailsHeader">
          <span className="tripId">Marshal Details</span>
        </div>
        <div className="center">
          <div className="marshalDetails" onClick={() => this.handleDial()}>
            <span className="name">{connectingMarshal.name}</span>
            <div className="callWrapper">
              <img
                src="https://d1flzashw70bti.cloudfront.net/original/images/optdash/callIcon.svg"
                alt="call marshal"
                className="callIcon"
              />
              <span className="text">Call</span>
            </div>
          </div>
        </div>
        <div className="mobileNo">
          <span className="seater">{connectingMarshal.mobileNo}</span>
        </div>
      </div>
    );
  }
}

export default MarshalDetailsTile;
