import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import { message } from "antd";
import { Fetch } from "../../utils/fetch";
import "./login.scss";
import Otpinput from "../../components/OtpInput";
import styles from "./desktop.module.scss";
import { operatorType } from "../../config";
import jwt from "jsonwebtoken";
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: "",
      login: false,
      showOTP: false,
      isMobileDevice: false,
      showOtpError: false,
      redirect: false,
      redirectUrl: "/",
    };
  }

  async componentDidMount() {
    const regex =
      /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    const isMobile = regex.test(navigator.userAgent);

    if (isMobile) {
      this.setState({ isMobileDevice: true });
    }

    // Retrieve the intended URL (if any) from sessionStorage
    const storedRedirectUrl = sessionStorage.getItem("redirectAfterLogin");
    if (storedRedirectUrl) {
      this.setState({ redirectUrl: storedRedirectUrl });
    }
  }

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };

  sendOtp = async () => {
    const path = `/user/login`;
    const { phone } = this.state;
    const options = {
      method: "post",
      data: {
        mobileNo: phone,
      },
    };

    if (phone.length < 10) {
      return message.error("Mobile Number is required");
    }
    const response = await Fetch(path, options);

    if (response == "otp sent") {
      this.setState({ showOTP: true });
    } else {
      message.error("User Not Registered");
    }
  };

  hideOtp = () => {
    this.setState({ showOTP: false });
  };

  verifyOTP = async (data) => {
    const url = `/user/verifyOtp`;

    const options = {
      method: "post",
      data,
    };

    const response = await Fetch(url, options);
    if (response == "invalid otp") {
      this.setState({ showOtpError: true });
    } else {
      const token = localStorage.getItem("idToken");
      const decodedToken = jwt.decode(token);
      const userType = decodedToken.userType;

      // Retrieve and clear the intended URL from sessionStorage
      const redirectUrl =
        sessionStorage.getItem("redirectAfterLogin") ||
        (userType === operatorType.CABOWNER ? "/trips" : "/");
      sessionStorage.removeItem("redirectAfterLogin");

      this.setState({ redirect: true, userType: userType, redirectUrl });
    }
  };

  resendOtp = async () => {
    const path = `/user/login`;
    const { phone } = this.state;
    const options = {
      method: "post",
      data: {
        mobileNo: phone,
      },
    };

    await Fetch(path, options);
  };

  render() {
    const {
      phone,
      showOTP,
      showOtpError,
      redirect,
      isMobileDevice,
      userType,
      redirectUrl,
    } = this.state;
    if (redirect) {
      return <Redirect to={redirectUrl} />;
    }
    if (isMobileDevice) {
      return (
        <div className="loginDiv">
          <div className="container">
            <div className="header">
              <img
                alt="logo"
                src="https://d1flzashw70bti.cloudfront.net/original/images/operator/login/logo/logo.svg"
              ></img>
            </div>
            <div className="login">
              <p className="textHeader">Login to Operator Dashboard</p>

              {showOTP ? (
                <Otpinput
                  isMobile={true}
                  number={phone}
                  hideOtp={this.hideOtp}
                  showOtpError={showOtpError}
                  verifyOTP={this.verifyOTP}
                />
              ) : (
                <>
                  <span className="textSubHeader">
                    Please enter your registered mobile number
                  </span>
                  <div className="mobileNumberInput">
                    <span>+91</span>
                    <div className="vl">
                      <input
                        type="text"
                        name="phoneNumber"
                        id="phoneNumber"
                        inputmode="numeric"
                        autoComplete="off"
                        pattern="\d*"
                        value={phone}
                        onChange={(e) => {
                          const re = /^[0-9\b]+$/;
                          if (
                            e.target.value.length < 11 &&
                            (e.target.value === "" || re.test(e.target.value))
                          ) {
                            this.handleChange("phone", e.target.value);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <button onClick={this.sendOtp}>Send OTP</button>
                </>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={styles.DesktopWrapper}>
          <div className={styles.background}></div>
          <div className={styles.detail}>
            <div className={styles.login}>
              <p className={styles.textHeader}>Welcome Operator </p>
              <p className={styles.textSubHeader}>Easy Login With Otp</p>
              {showOTP ? (
                <>
                  <Otpinput
                    isMobile={false}
                    number={phone}
                    hideOtp={this.hideOtp}
                    showOtpError={showOtpError}
                    verifyOTP={this.verifyOTP}
                  />
                </>
              ) : (
                <>
                  <p className={styles.textSubHeader}>
                    Enter Registered Number
                  </p>
                  <div className={styles.mobileNumberInput}>
                    <span>+91</span>
                    <div className="vl">
                      <input
                        type="text"
                        name="phoneNumber"
                        id="phoneNumber"
                        inputmode="numeric"
                        autoComplete="off"
                        pattern="\d*"
                        value={phone}
                        onChange={(e) => {
                          const re = /^[0-9\b]+$/;
                          if (
                            e.target.value.length < 11 &&
                            (e.target.value === "" || re.test(e.target.value))
                          ) {
                            this.handleChange("phone", e.target.value);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <button onClick={this.sendOtp}>Send OTP</button>
                </>
              )}
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Login;
