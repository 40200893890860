// CitySelectionOverlay.js
import React, { Component } from "react";
import "./citySelectionOverlay.scss";

class CitySelectionOverlay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: "",
    };
  }

  handleSearchChange = (event) => {
    this.setState({ searchQuery: event.target.value });
  };

  handleSelectCity = (city) => {
    const { onCitySelect, closeOverlay } = this.props;
    onCitySelect(city);
    closeOverlay();
  };

  render() {
    const { cities, closeOverlay } = this.props;
    const { searchQuery } = this.state;

    // Filter cities based on the search query
    const filteredCities = cities.filter((city) =>
      city.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
      <div className="city-selection-overlay">
        <div className="overlay-header">
          <input
            type="text"
            placeholder="Search city"
            value={searchQuery}
            onChange={this.handleSearchChange}
            className="search-input"
          />
          <img
            src="https://d1flzashw70bti.cloudfront.net/original/images/opdash/sidemenu/closecross.svg"
            alt="close"
            className="close-button"
            onClick={closeOverlay}
          />
        </div>
        <div className="city-list">
          {filteredCities.map((city, index) => (
            <div
              className="city-item"
              key={index}
              onClick={() => this.handleSelectCity(city)}
            >
              <span className="city-name">{city}</span>
              <span className="select-text">Select</span>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default CitySelectionOverlay;
