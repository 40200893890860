import React, { Component } from "react";
import "./midScreenPopUp.scss";

class MidScreenPopUp extends Component {
  handleButtonClick = () => {
    const { onClose } = this.props;
    if (onClose) {
      onClose();
    }
  };

  render() {
    const { imageUrl, text, subText, buttonText, onClose } = this.props;

    return (
      <div className="midScreenPopUpBackground" onClick={onClose}>
        <div
          className="midScreenPopUpContainer"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {imageUrl && (
            <img src={imageUrl} alt="Popup Visual" className="popupImage" />
          )}
          {text && <p className="popupText">{text}</p>}
          {subText && <p className="popupSubText">{subText}</p>}
          <button
            className="popupButton"
            onClick={() => this.handleButtonClick()}
          >
            {buttonText}
          </button>
        </div>
      </div>
    );
  }
}

export default MidScreenPopUp;
