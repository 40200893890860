import React from "react";
import PropTypes from "prop-types";
import "./mobileSubHeader.scss";

const MobileSubHeader = ({
  routeName,
  isMainHeaderHidden,
  toggleBottomDrawer,
}) => {
  const handleBack = () => {
    window.history.back();
  };

  const getContent = () => {
    switch (routeName) {
      case "trips":
        return (
          <>
            <div className="subHeaderText">Trips</div>
            <img
              src="https://d1flzashw70bti.cloudfront.net/original/images/operatordash/headers/searchIcon.svg"
              alt="Search Icon"
              className="subHeaderIcon"
              onClick={() => toggleBottomDrawer()}
            />
          </>
        );

      case "trips/tripDetails":
        return (
          <div className="subHeaderContent">
            <img
              src="https://d1flzashw70bti.cloudfront.net/original/images/optdash/subheader/back.svg"
              alt="Back Arrow Icon"
              className="subHeaderIcon"
              style={{ marginRight: "15px", width: "18px" }}
              onClick={handleBack}
            />
            <span className="subHeaderText">Trip Details</span>
          </div>
        );

      default:
        return <div className="subHeaderText">Default Page</div>;
    }
  };

  return (
    <div
      className={
        isMainHeaderHidden ? "mobileSubHeader moveUp" : "mobileSubHeader"
      }
    >
      {getContent()}
    </div>
  );
};

MobileSubHeader.propTypes = {
  routeName: PropTypes.string.isRequired, // dynamic route name
};

export default MobileSubHeader;
