import React, { Component } from "react";
import MobileMainHeader from "../../components/headers/mobileMainHeader";
import { MenuSlideDrawer } from "../../components/slideDrawer/SlideDrawer";
import SideMenu from "../../components/Layout/SideMenu";
import Navbar from "../../components/newNavBar";
import Loader from "../../utils/loader";
import { Fetch } from "../../utils/fetch";
import "../home/home.scss";
import TripTile from "../../components/tiles/tripTile";
import BottomDrawer from "../../components/Layout/bottomDrawer";
import AssignDriverForm from "../../components/assignDriverForm/assignDriverForm";
import TripSearchFilters from "../../components/tripSearchFilters.js";
import AppliedFiltersTabs from "../../components/appliedFiltersTabs/index.js";
import EmptyView from "../../components/emptyView/index.js";

class Trips extends Component {
  state = {
    loading: true,
    isMobile: false,
    showSideMenu: false,
    trips: [],
    tripsIn12HrsRange: [],
    isBottomDrawerOpen: false,
    fromDateFilter: "",
    toDateFilter: "",
    fromCityFilter: "",
    toCityFilter: "",
    tripIdFilter: "",
    filtersAppliedCount: 0,
    isFromAndCityFilterApplied: false,
    isFromAndToDateFilterApplied: false,
    isTripIdFilterApplied: false,
  };

  async componentDidMount() {
    try {
      const regex =
        /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
      const isMobile = regex.test(navigator.userAgent);

      const path = `/cabOperator/trips`;
      const options = {
        method: "get",
      };
      const assignedTrips = await Fetch(path, options);

      this.setState({
        loading: false,
        isMobile,
        trips:
          assignedTrips && Array.isArray(assignedTrips) ? assignedTrips : [],
      });

      const tripsIn12HrsRange = this.filterTripsMinusAndPlus12Hrs();
      this.setState({ tripsIn12HrsRange: tripsIn12HrsRange });
    } catch (err) {
      console.log(err);
    }
  }

  filterTripsMinusAndPlus12Hrs = () => {
    const { trips } = this.state;
    const currentTime = new Date().getTime(); // Current time in milliseconds
    const twelveHours = 12 * 60 * 60 * 1000; // 12 hours in milliseconds

    // Filter trips within 12 hours before or after the current time
    const filteredAndSortedTrips = trips
      .filter((trip) => {
        // Include trips where the startTime is within 12 hours before or after the current time
        return Math.abs(trip.startTime - currentTime) <= twelveHours;
      })
      .sort((a, b) => {
        // Calculate the time difference for each trip's startTime relative to the current time
        const aDiff = a.startTime - currentTime; // Difference for trip 'a'
        const bDiff = b.startTime - currentTime; // Difference for trip 'b'

        // Sorting logic:
        if (aDiff >= 0 && bDiff >= 0) {
          // Both trips have start times ahead of the current time
          // Sort in ascending order of startTime - currentTime
          return aDiff - bDiff;
        } else if (aDiff >= 0 && bDiff < 0) {
          // Trip 'a' is ahead of current time, but trip 'b' has crossed
          // 'a' should come before 'b'
          return -1;
        } else if (aDiff < 0 && bDiff >= 0) {
          // Trip 'b' is ahead of current time, but trip 'a' has crossed
          // 'b' should come before 'a'
          return 1;
        } else {
          // Both trips have start times that have already crossed the current time
          // Sort in ascending order of the absolute difference |startTime - currentTime|
          return Math.abs(aDiff) - Math.abs(bDiff);
        }
      });
    return filteredAndSortedTrips;
  };

  toggleSideMenu = () => {
    const { showSideMenu } = this.state;
    this.setState({
      showSideMenu: !showSideMenu,
    });
  };

  toggleBottomDrawer = () => {
    this.setState((prevState) => ({
      isBottomDrawerOpen: !prevState.isBottomDrawerOpen,
    }));
  };

  applySearchFilters = (fromCity, toCity, fromDate, toDate, tripId) => {
    let {
      filtersAppliedCount,
      isFromAndCityFilterApplied,
      isFromAndToDateFilterApplied,
      isTripIdFilterApplied,
    } = this.state;
    if (fromCity && toCity && !isFromAndCityFilterApplied) {
      this.setState({
        filtersAppliedCount: ++filtersAppliedCount,
        isFromAndCityFilterApplied: true,
      });
    }
    if (fromDate && toDate && !isFromAndToDateFilterApplied) {
      this.setState({
        filtersAppliedCount: ++filtersAppliedCount,
        isFromAndToDateFilterApplied: true,
      });
    }
    if (tripId && !isTripIdFilterApplied) {
      this.setState({
        filtersAppliedCount: ++filtersAppliedCount,
        isTripIdFilterApplied: true,
      });
    }

    this.setState({
      fromCityFilter: fromCity,
      toCityFilter: toCity,
      fromDateFilter: fromDate,
      toDateFilter: toDate,
      tripIdFilter: tripId,
    });
  };

  onRemoveFilters = (filter) => {
    const { filtersAppliedCount } = this.state;
    if (filter.fitlerType === "FROM_AND_TO_DATE_FILTER") {
      this.setState({
        fromDateFilter: "",
        toDateFilter: "",
        filtersAppliedCount: filtersAppliedCount - 1,
        isFromAndCityFilterApplied: false,
      });
    }
    if (filter.fitlerType === "FROM_AND_TO_CITY_FILTER") {
      this.setState({
        fromCityFilter: "",
        toCityFilter: "",
        filtersAppliedCount: filtersAppliedCount - 1,
        isFromAndToDateFilterApplied: false,
      });
    }
    if (filter.fitlerType === "TRIP_ID_FILTER") {
      this.setState({
        tripIdFilter: "",
        filtersAppliedCount: filtersAppliedCount - 1,
        isTripIdFilterApplied: false,
      });
    }
  };

  render() {
    const {
      showSideMenu,
      isMobile,
      loading,
      isBottomDrawerOpen,
      trips,
      tripsIn12HrsRange,
      filtersAppliedCount,
      fromCityFilter,
      toCityFilter,
      fromDateFilter,
      toDateFilter,
      tripIdFilter,
    } = this.state;
    let active = 6;
    if (loading) {
      return (
        <div className="loader">
          <Loader />
        </div>
      );
    }

    let filteredTrips = [];
    if (filtersAppliedCount > 0) {
      filteredTrips = trips.filter((trip) => {
        // Apply filters
        const isFromAndToCityMatch =
          fromCityFilter && toCityFilter
            ? trip.from === fromCityFilter && trip.to === toCityFilter
            : true; // If no city filter, it's always true

        const isFromAndToDateMatchd =
          fromDateFilter && toDateFilter
            ? trip.startTime > fromDateFilter && trip.startTime <= toDateFilter
            : true; // If no date filter, it's always true

        const isTripIdMatch = tripIdFilter
          ? trip.groupCode === tripIdFilter
          : true; // If no specific tripId filter, it's always true

        // Return true for trips that meet the applied filters
        return isFromAndToCityMatch && isFromAndToDateMatchd && isTripIdMatch;
      });
    } else {
      // If no filters are applied, show all trips
      filteredTrips = tripsIn12HrsRange;
    }

    return (
      <>
        {isMobile ? (
          <div style={{ overflowX: "hidden" }}>
            <MobileMainHeader
              toggleSideMenu={this.toggleSideMenu}
              hasSubHeader={true}
              toggleBottomDrawer={this.toggleBottomDrawer}
            />
            {filtersAppliedCount > 0 ? (
              <AppliedFiltersTabs
                fromCityFilter={fromCityFilter}
                toCityFilter={toCityFilter}
                fromDateFilter={fromDateFilter}
                toDateFilter={toDateFilter}
                tripIdFilter={tripIdFilter}
                onRemoveFilters={this.onRemoveFilters}
              />
            ) : null}
            {filteredTrips.length > 0 ? (
              filteredTrips.map((trip, index) => (
                <TripTile
                  key={trip.id}
                  index={index}
                  tripData={trip}
                  isAnyFilterApplied={filtersAppliedCount > 0}
                />
              ))
            ) : (
              <div>
                <EmptyView
                  message={
                    filtersAppliedCount > 0
                      ? "Trips Not Found"
                      : "No Trips Assigned"
                  }
                />
              </div>
            )}
            {showSideMenu ? (
              <MenuSlideDrawer
                toggleSideMenu={this.toggleSideMenu}
                showSideMenu={showSideMenu}
                isMobile={isMobile}
              >
                <SideMenu
                  active={active}
                  toggleSideMenu={this.toggleSideMenu}
                />
              </MenuSlideDrawer>
            ) : null}
            {isBottomDrawerOpen ? (
              <BottomDrawer
                heading="Search"
                isOpen={isBottomDrawerOpen}
                onClose={this.toggleBottomDrawer}
              >
                <TripSearchFilters
                  onCloseDrawer={this.toggleBottomDrawer}
                  trips={trips}
                  applySearchFilters={this.applySearchFilters}
                  fromCityFilter={fromCityFilter}
                  toCityFilter={toCityFilter}
                  fromDateFilter={fromDateFilter}
                  toDateFilter={toDateFilter}
                  tripIdFilter={tripIdFilter}
                />
              </BottomDrawer>
            ) : null}
          </div>
        ) : (
          <>
            <Navbar selectedKey={1} isMobile={false} />
          </>
        )}
      </>
    );
  }
}

export default Trips;
