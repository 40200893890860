import axios from "axios";
import { baseURL, operatorType } from "../config";
import jwt from "jsonwebtoken";

const getNewToken = async () => {
  const refreshToken = localStorage.getItem("refreshToken");
  const headers = {};
  headers.refreshtoken = `Bearer ${refreshToken}`;

  const response = await axios({
    method: "get",
    url: `${baseURL}/user/updateToken`,
    headers,
  });
  const { data, statusCode } = response.data;
  if (statusCode === "error") {
    return true;
  } else {
    localStorage.setItem("idToken", data);
    let newToken = data;
    return newToken;
  }
};

export const Fetch = async (path, options = {}, authorization = false) => {
  try {
    const token = localStorage.getItem("idToken");
    const decodedToken = jwt.decode(token);
    if (authorization && !token) {
      throw new Error("No JWT token found");
    }
    const headers = {
      ...options.headers,
    };
    if (token) {
      headers.authorization = `Bearer ${token}`;
    }
    let url = `${baseURL}${path}`;
    if (decodedToken && decodedToken.id) {
      headers.userId = decodedToken.id;
    } else if (decodedToken && decodedToken.mgId) {
      headers.userId = decodedToken.mgId;
    }
    if (decodedToken && decodedToken.userType === operatorType.BUSOWNER) {
      url = `${baseURL}/mg${path}`;
    }

    const response = await axios({
      method: "get",
      url: `${url}`,
      ...options,
      headers,
    });
    const { data, statusCode } = response.data;
    if (statusCode === "error" && data === "failed with 401") {
      //update token
      let newToken = await getNewToken();
      const decodedToken = jwt.decode(newToken);

      headers.userId = decodedToken.id;
      if (newToken == true) {
        localStorage.removeItem("idToken");
        this.props.history.replace("/login");
      }

      //retry request
      headers.authorization = `Bearer ${newToken}`;

      if (decodedToken && decodedToken.id) {
        headers.userId = decodedToken.id;
      } else if (decodedToken && decodedToken.mgId) {
        headers.userId = decodedToken.mgId;
      }
      if (decodedToken && decodedToken.userType === operatorType.BUSOWNER) {
        url = `${baseURL}/mg${path}`;
      }

      const newResponse = await axios({
        method: "get",
        url: `${url}`,
        ...options,
        headers,
      });
      const { data, statusCode } = newResponse.data;
      if (statusCode === "ok") {
        return data;
      }
    } else {
      if (data && data.refreshToken && data.accessToken) {
        localStorage.setItem("idToken", data.accessToken);
        localStorage.setItem("refreshToken", data.refreshToken);
      }
      return data;
    }
  } catch (err) {
    console.log(err);
  }
};

export const downloadPDF = async (
  path,
  options = {},
  authorization = false
) => {
  try {
    const token = localStorage.getItem("idToken");
    const decodedToken = jwt.decode(token);
    if (authorization && !token) {
      throw new Error("No JWT token found");
    }
    const headers = {
      ...options.headers,
    };

    if (token) {
      headers.authorization = `Bearer ${token}`;
    }
    let url = `${baseURL}${path}`;
    if (decodedToken && decodedToken.id) {
      headers.userId = decodedToken.id;
    }
    if (decodedToken && decodedToken.isMGUser && decodedToken.mgId) {
      headers.userId = decodedToken.mgId;
      url = `${baseURL}/mg${path}`;
    }

    const response = await axios({
      method: "get",
      responseType: "arraybuffer",
      url: `${url}`,
      ...options,
      headers,
    });
    return response.data;
  } catch (err) {
    console.log(err);
  }
};
